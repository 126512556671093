html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(Roboto Light), url("Roboto-Light.954f5b9a.woff2") format("woff"), url("Roboto-Light.6d7b26d7.woff") format("truetype"), url("Roboto-Light.6ac97cc5.eot") format("embedded-opentype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(Roboto Bold), url("Roboto-Bold.199ebd77.woff2") format("woff"), url("Roboto-Bold.c0f0e13f.woff") format("truetype"), url("Roboto-Bold.27b5d923.eot") format("embedded-opentype");
}

html {
  padding: 0 3rem;
}

body {
  padding: 0% 1%;
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.2em;
}

a {
  color: #ff0048;
  background: linear-gradient(25deg, #0095ff, #ff0048);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 600;
  text-decoration: none;
}

a:hover, a:focus {
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  -webkit-text-fill-color: #fff;
  -moz-text-fill-color: #fff;
  text-fill-color: #fff;
  color: #fff;
  background-color: #ff0048;
  background-clip: border-box;
}

footer {
  width: 100%;
  flex-direction: row;
  justify-content: right;
  font-size: .5rem;
  display: flex;
}

footer div:last-child {
  width: 100%;
  text-align: right;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  display: inline-block;
}

.copyright:before {
  content: "©";
}

@media (min-width: 500px) {
  html {
    padding: 0 5rem;
  }

  body {
    font-size: 40px;
  }

  footer {
    font-size: 1rem;
  }
}

@media (min-width: 1000px) {
  html {
    padding: 0 10rem;
  }

  body {
    font-size: 64px;
  }

  footer {
    font-size: 1.5rem;
  }
}

@media (min-width: 1500px) {
  html {
    padding: 0 15rem;
  }
}

@media (min-width: 2000px) {
  html {
    padding: 0 20rem;
  }
}

/*# sourceMappingURL=index.637d10ad.css.map */
